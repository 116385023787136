import { Button } from '@chakra-ui/react'
import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { WIDTH_BOUNDARY, useWindowDimensions } from 'UseWindowDimensions'
import clsx from 'clsx'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import useNotificationMerchant from 'hooks/useNotificationMerchant'
import { ReactComponent as UserCircleSVG } from 'icons/account_circle.svg'
import { ReactComponent as QRCodeSVG } from 'icons/qr_code.svg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'util/formatDateTime'
import { TokenBalance } from '../../functions/src/data/common'

interface TextIcon {
  icon: React.ReactNode
  message: string | React.ReactNode
}
interface INotificationPanelProps {
  className?: string
  children?: React.ReactNode
  firebaseDb: FirebaseDb
  merchantId: string | undefined
  user?: User
}

const NotificationPanel: React.FC<INotificationPanelProps> = (props) => {
  const { recentEvents: dataList } = useNotificationMerchant({
    firebaseDb: props.firebaseDb,
    merchantId: props.merchantId,
    user: props.user,
  })

  const windowDimensions = useWindowDimensions()

  const revertTitle = (text: string): TextIcon => {
    if (text.includes('Card created for')) {
      return {
        icon: <UserCircleSVG className='h-8 w-8' />,
        message: <span>{text}</span>,
      }
    } else if (text.includes('scanned your store qr-code')) {
      return {
        icon: <QRCodeSVG className='h-8 w-8' />,
        message: <span>{text}</span>,
      }
    } else {
      return {
        icon: <></>,
        message: text,
      }
    }
  }

  // if (type === 'PENDING') {
  //   return <NotificationSkeleton />
  // }
  return (
    <div
      className={clsx(
        props.className,
        `no-scrollbar mb: w-full overflow-y-auto py-2 pb-16 pt-4 lg:rounded lg:bg-white lg:p-2 lg:pb-2
        lg:shadow-md`,
      )}>
      <h2 className='mb-4 pt-0 text-xl font-semibold lg:bg-white lg:p-2'>
        {windowDimensions.width <= WIDTH_BOUNDARY ? 'My History' : 'Notifications'}
      </h2>
      <div className='space-y-2'>
        {dataList?.map((it) => {
          const { icon, message } = revertTitle(it.title)
          return (
            <div
              key={it.timestamp}
              className='border-b-2 border-gray-200 pb-4'>
              <div className='flex items-start gap-x-2 rounded-md p-2'>
                <div>{icon}</div>
                <div className='text-slate-600'>
                  <div className='flex flex-col items-start justify-start gap-2 text-sm'>
                    {message}
                  </div>
                  <div style={{ fontSize: '0.8em' }}>{formatDate(it.timestamp)}</div>
                </div>
              </div>
              {it.title && (
                <div className='text-center'>
                  <RewardButton
                    url={it.link}
                    firebase={props.firebaseDb}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NotificationPanel

export const RewardButton: React.FC<{ url: string; firebase: FirebaseDb }> = ({
  url,
  firebase,
}) => {
  const navigate = useNavigate()

  return (
    <Button
      className='rounded-lg bg-[#bf997f] px-3 py-1 text-csm text-white transition-colors duration-150
        hover:cursor-pointer hover:brightness-110'
      onClick={() => navigate(url)}>
      Reward
    </Button>
  )
}
