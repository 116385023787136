import { Spinner } from '@chakra-ui/react'
import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { useMerchantStore } from 'UseMerchantStore'
import MerchantCard from 'components/card/MerchantCard'
import { useMemo } from 'react'
import { ICard, LiveMerchantCardProps } from 'types/card.module'

const LiveMerchantCard: React.FC<LiveMerchantCardProps> = ({
  merchantId,
  firebaseDb,
  onClick,
  cardId,
  className,
  cardClassName,
}) => {
  const state = useLoyaltyCardStore(cardId, firebaseDb)
  const merchantState = useMerchantStore(merchantId, firebaseDb)
  const cardEntry = state.type === 'SUCCESS' ? state.cardData : null

  const cardConfig = useMemo(() => {
    if (!cardEntry) return null

    const template = cardEntry.balance?.[cardEntry.templateId]
    if (!template) return null

    const points =
      (template &&
        template.templateType === 'tokens' &&
        template.target &&
        Array.from({ length: template.target }, (_, i) => i + 1)) ||
      []

    return {
      logo: cardEntry.logo,
      name: cardEntry.title || undefined,
      primaryColor: template?.primaryColor || undefined,
      secondaryColor: template?.secondaryColor || undefined,
      isCreate: false,
      markablePoints: points,
      storedMarkedPoints: template?.templateType === 'tokens' ? template.marked : undefined,
    } satisfies ICard
  }, [cardEntry])

  if (!cardConfig && merchantState.type === 'PENDING') {
    return <Spinner className='h-20 w-20 md:h-26 md:w-26' />
  }

  if (!cardConfig) {
    return <></>
  }

  return (
    <MerchantCard
      cardClassName={cardClassName}
      className={className}
      onClick={() => onClick && onClick({ merchantId, cardId })}
      cardConfig={cardConfig}
    />
  )
}
export default LiveMerchantCard
