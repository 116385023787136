import { motion } from 'framer-motion'
import React from 'react'

export const Transition = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode
    [other: string]: any
  }
>(function Transition({ children, ...props }, ref) {
  return (
    <motion.div
      ref={ref}
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.5 }}
      {...props}>
      {children}
    </motion.div>
  )
})

export default Transition
