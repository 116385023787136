import { loadStripe } from '@stripe/stripe-js'
import { Price } from 'data/stripe/StripeProduct'
import { User } from 'firebase/auth'
import { FirebaseDb, FirestoreDb } from '../components/common/Firebase'
import { LineItemParams, SessionCreateParams } from '../data/stripe/StripeSession'

type LoadCheckoutProps = {
  firestoreDb: FirestoreDb
  firebaseDb: FirebaseDb
  price: Price
  user: User
  lineItems?: LineItemParams[]
  quantity?: number
  metadata?: {
    [key: string]: string | number | null | undefined
  }
}

export const loadCheckout = async ({
  firestoreDb,
  firebaseDb,
  price,
  user,
  quantity = 1,
  metadata,
  lineItems,
}: LoadCheckoutProps) => {
  return new Promise(async (resolve, reject) => {
    const doc = await firestoreDb
      .collection('users')
      .doc(user.uid)
      .collection('checkout_sessions')
      .add({
        mode: price.type === 'one_time' ? 'payment' : 'subscription',
        price: price.id,
        line_items: lineItems,
        quantity,
        success_url: `${window.location.toString()}?success=true&orderId=${metadata?.orderId}`,
        cancel_url: `${window.location.toString()}?canceled=true&orderId=${metadata?.orderId}`,
        allow_promotion_codes: true,
        metadata: { uid: user.uid, ...metadata },
      } satisfies SessionCreateParams)

    doc.onSnapshot(async (snap) => {
      const { error, sessionId } = snap.data() as {
        error?: { message: string }
        sessionId?: string
      }

      if (error) {
        alert(`An error occurred: ${error.message}`)
        resolve(undefined)
        return
      }

      if (sessionId) {
        const key = process.env.REACT_APP_STRIPE_API_KEY
        if (!key && process.env.NODE_ENV === 'development') {
          console.error('Stripe public key is undefined')
          resolve(undefined)
        }
        if (key !== undefined) {
          const stripe = await loadStripe(key)
          if (!stripe && process.env.NODE_ENV === 'development') {
            console.error('Stripe is undefined')
            resolve(undefined)
          }

          stripe?.redirectToCheckout({ sessionId })
          resolve(stripe)
        }
      }
    })
  })
}
