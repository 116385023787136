import clsx from 'clsx'
import { cn } from 'components/common/utils/tailwindUtils'

interface OptionPaymentItemProps {
  price: number
  title: string
  description: string | React.ReactNode
  extra?: string
  isActive?: boolean
  onClick?: () => void
  isSelectIcon?: boolean
  className?: string
  [key: string]: any
}

export const OptionPaymentItem: React.FC<OptionPaymentItemProps> = ({
  className,
  price,
  title,
  description,
  onClick,
  isActive,
  extra,
  isSelectIcon = true,
  ...props
}) => {
  return (
    <div
      {...props}
      onClick={onClick}
      className={cn(`min-h-20 rounded-xl border p-2 hover:cursor-pointer ${className}`, {
        'border-gray-300': !isActive,
        'border-light-primary': isActive,
      })}>
      <div className='flex w-full items-center justify-between gap-2.5'>
        <div className='flex items-center gap-2.5'>
          {isSelectIcon && (
            <div
              className={clsx('h-3 w-3 rounded-full border border-gray-600 bg-light-primary p-1', {
                'bg-transparent': !isActive,
              })}></div>
          )}
          <div className='pr-2'>
            <p className='text-sm font-semibold'>{title}</p>
            <p className='text-csm text-gray-500'>{description}</p>
          </div>
        </div>
        <div className='min-w-26 text-right'>
          <p className='text-2xl font-bold'>
            {price ?
              `${price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`
            : ''}
          </p>
          {extra && <p className='relative text-xs'>({extra})</p>}
        </div>
      </div>
    </div>
  )
}
