import { useUserCardsStore } from 'UseUserCardsStore'
import { useQueryString } from 'components/QueryString'
import { useFirebase } from 'components/common/Firebase'
import { useFirebaseUi } from 'components/common/UseFirebaseUi'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import SignInForm from './components/SignInForm'
import { TwCard } from './twSignIn.module'

const Login: React.FC = () => {
  const navigate = useNavigate()

  const [hasUserData, setHasUserData] = useState(true)

  const { auth: loyaltyAuth, firebaseDb } = useFirebase(firebaseConfig)
  const [redirectLocation] = useQueryString('redirect', '/')

  const { userSignedIn } = useFirebaseUi({
    wrappedAuth: loyaltyAuth,
    linkAccounts: hasUserData,
    redirectUrl: redirectLocation,
    disableOptions: { facebook: true },
  })
  const results = useUserCardsStore(userSignedIn, firebaseDb)

  useEffect(() => {
    if (results.type === 'SUCCESS' && Object.keys(results.cardsData).length) {
      setHasUserData(true)
    } else {
      setHasUserData(false)
    }
  }, [results])


  useEffect(() => {
    if (userSignedIn) {
      firebaseDb.get(`users/${userSignedIn.uid}`).then((user) => {
        if (!user.exists()) firebaseDb.getRef(`users/${userSignedIn.uid}/isMerchant`).set(false)
      })
      if (!userSignedIn.isAnonymous) navigate(redirectLocation ?? '/')
    }
  }, [userSignedIn, firebaseDb, navigate, redirectLocation])

  return (
    <div className='flex min-h-screen items-center justify-center lg:items-start lg:pt-32'>
      <TwCard>
        <SignInForm />
        {/* <div className='flex items-center justify-center my-6'>
          <TwTheLines />
          <span className='mx-4 text-gray-500'>Or sign in with</span>
          <TwTheLines />
        </div>
        <SocialOption /> */}
        {/* <p className='text-center text-gray-600 mt-6 text-sm'>
          Don’t have an account?{' '}
          <a
            href='#'
            className='text-light-primary'>
            Sign up here
          </a>
        </p> */}
      </TwCard>
    </div>
  )
}
export default Login

