import { TwLableForm } from 'components/modules/twComponent/twLayout.module'
import Slider from 'components/slider'
import { forwardRef } from 'react'
import { FieldValues } from 'react-hook-form'
import { CustomInputProps } from './CustomInput'

const SliderInput = forwardRef<HTMLInputElement, CustomInputProps<FieldValues>>(
  ({ type, error, name, label, onChange, max, value, ...rest }, ref) => {
    return (
      <div>
        <TwLableForm>{label}</TwLableForm>
        <div className='flex gap-x-4'>
          <div className='w-full'>
            <Slider
              min={0}
              max={max}
              step={1}
              value={value}
              initialValue={value}
              onChange={onChange}
            />
          </div>
          <input
            ref={ref}
            id={name}
            name={name}
            value={value}
            type={type}
            onChange={onChange && ((event) => onChange?.(event.target.value))}
            className={`h-9 w-12 rounded border text-center leading-tight text-gray-700 shadow focus:outline-none
              ${error ? 'border-red-500' : ''}`}
            {...rest}
          />
        </div>
      </div>
    )
  },
)
SliderInput.displayName = 'SliderInput'
export default SliderInput
