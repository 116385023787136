import { CloseIcon } from '@chakra-ui/icons'
import { useUserTeamsStore } from 'UseMerchantStore'
import { useUserCardsStore } from 'UseUserCardsStore'
import { DraggableScrollBox } from 'components/DraggableScrollBox'
import RenderIf from 'components/RenderIf'
import Transition from 'components/Transition'
import AddMerchantCard from 'components/card/AddMerchantCard'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { cn } from 'components/common/utils/tailwindUtils'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { PATH } from 'constants/routes'
import LiveMerchantCard from 'pages/Merchant/components/LiveMerchantCard'
import { LiveLoyaltyCard } from 'pages/card/LoyaltyCardStamper'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'

// Define card properties
export interface SelectedCardProps {
  merchantId: string
  cardId: string
}

const Dashboard: React.FC = () => {
  const streamRef = useRef<MediaStream | null>(null)
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const navigate = useNavigate()
  const { user } = useUserNullable({ auth: firebase.auth })

  const userTeams = useUserTeamsStore(user, firebaseDb)
  const state = useUserCardsStore(user, firebaseDb)

  const [selectedCardId, setSelectedCardId] = useState<SelectedCardProps>()
  const [isCameraOpen, setIsCameraOpen] = useState(false)

  const handleScan = (result: string | null) => {
    if (result) {
      setIsCameraOpen(false)
      window.location.href = result
    }
  }

  const handleError = (error: any) => {
    console.error('Error accessing camera:', error)
  }

  const handleOpenCamera = () => setIsCameraOpen(true)
  const handleCloseCamera = () => setIsCameraOpen(false)

  const onClickSelectedCard = (info: SelectedCardProps) => {
    setSelectedCardId(info)
  }

  useEffect(() => {
    const currentStreamRef = streamRef.current
    return () => {
      if (currentStreamRef) {
        currentStreamRef.getTracks().forEach((track) => track.stop())
      }
    }
  }, [isCameraOpen])

  useEffect(() => {
    if (state.type === 'PENDING' || userTeams.type === 'PENDING') return
    if (userTeams.type === 'SUCCESS' && !userTeams.teams && state.type === 'NO_CARD') {
      navigate(PATH.MERCHANT_CREATE)
    }
    const merchantIds = userTeams.teams && Object.keys(userTeams.teams.permissions)
    if (userTeams.type === 'SUCCESS' && merchantIds?.length && state.type === 'NO_CARD') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      navigate(PATH.MERCHANT_DASHBOARD.replace(':id', merchantIds.firstOrNull()!))
    }
  }, [userTeams, navigate, state])

  if (userTeams.type === 'PENDING' || state.type === 'PENDING') {
    return <SkeletonHome />
  }

  return (
    <section
      className='layout shrink-1 relative mt-4 h-fit max-h-full max-w-[31.25rem] bg-light-mb-main md:pt-8 lg:h-[80vh]
        lg:bg-white lg:pt-4'>
      <RenderIf isTrue={!!user?.uid}>
        <div className='shrink-1 flex min-h-0 flex-col flex-nowrap gap-4 pb-2.5 md:gap-y-8 lg:mt-2'>
          <span className='hidden px-2.5 text-xl font-semibold capitalize md:block lg:px-4'>
            My programs
          </span>

          {state.type === 'SUCCESS' && !!Object.entries(state.cardsData).length && (
            <DraggableScrollBox
              direction={'horizontal'}
              className='flex-none'>
              <div className='no-scrollbar my-0 flex w-full gap-3 px-2.5 leading-none lg:justify-center lg:px-4'>
                {Object.entries(state.cardsData)
                  .flatMap(([merchantId, cardRefsByConfig]) => {
                    return Object.entries(cardRefsByConfig).map(([cardConfigId, cardRef]) => ({
                      merchantId,
                      cardConfigId,
                      cardId: cardRef.cardId,
                    }))
                  })
                  .map(({ merchantId, cardId, cardConfigId }, index, array) => {
                    const isLastCard = index === array.length - 1
                    return (
                      <React.Fragment key={`${merchantId}-${cardConfigId}`}>
                        <LiveMerchantCard
                          cardClassName={cn(
                            'transition-all duration-300',
                            selectedCardId?.cardId === cardId && 'shadow-xl scale-105',
                          )}
                          cardId={cardId}
                          onClick={onClickSelectedCard}
                          merchantId={merchantId}
                          firebaseDb={firebaseDb}
                        />
                        {isLastCard && <AddMerchantCard onClick={handleOpenCamera} />}
                      </React.Fragment>
                    )
                  })}
              </div>
            </DraggableScrollBox>
          )}

          {selectedCardId?.cardId && (
            <Transition
              key={selectedCardId?.cardId}
              className='relative h-full flex-1 px-2.5 lg:px-4'>
              <LiveLoyaltyCard
                merchantId={selectedCardId?.merchantId}
                id={selectedCardId?.cardId}
                firebaseDb={firebaseDb}
                user={user}
              />
            </Transition>
          )}
        </div>

        {isCameraOpen && (
          <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80'>
            <button
              onClick={handleCloseCamera}
              className='absolute right-4 top-4 rounded-full text-white'
              aria-label='Close Camera'>
              <CloseIcon />
            </button>
            <div className='flex h-full w-full flex-col items-center justify-between gap-8 py-14'>
              {/* <Scanner
                onScan={(result) => {
                  if (!!result) handleScan(result.firstOrNull()?.rawValue ?? null)
                }}
                styles={{
                  video: {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    borderRadius: '0.5rem',
                  },
                }}
                constraints={{ facingMode: 'environment' }}
                classNames={{ container: 'w-4/5 max-w-md' }}
              /> */}
              <div className='flex w-4/5 flex-col items-center bg-[#DED2C5] px-4 py-8'>
                <p className='text-center text-xs font-medium tracking-wide text-gray-600'>
                  POWERED BY
                </p>
                <h1 className='text-center text-4xl font-bold tracking-widest'>
                  <span className='text-black'>RE</span>
                  <span className='text-white'>CLA</span>
                  <span className='text-black'>IM</span>
                </h1>
                <p className='mt-2 text-center text-xs font-medium tracking-wide text-gray-600'>
                  THE SIMPLE WAY TO REWARD
                </p>
              </div>
            </div>
          </div>
        )}
      </RenderIf>

      {state.type === 'NO_CARD' && (
        <div className='mt-4 space-y-6 text-center'>
          <div className='flex flex-col gap-4'>
            <span>Loading Loyalty programs in your area...</span>
            <span>Scan a Loyalty QR to get started</span>
          </div>
        </div>
      )}
    </section>
  )
}

export default Dashboard
