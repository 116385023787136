import { ArrowRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { useUserTeamsStore } from 'UseMerchantStore'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import clsx from 'clsx'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import { cn } from 'components/common/utils/tailwindUtils'
import ProfileSettings from 'components/drawer'
import { PATH } from 'constants/routes'
import useGreeting from 'hooks/useGreeting'
import { ReactComponent as UserCircleSVG } from 'icons/account_circle.svg'
import { useCallback, useMemo, useState } from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { Link, redirect, useLocation, useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'

interface HeaderProps {
  className?: string
}

const Header: React.FC<HeaderProps> = ({ className }) => {
  const firebase = useFirebase(firebaseConfig)
  const location = useLocation()
  const loyaltyAuth = firebase.auth
  const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const { user } = useUserNullable({ auth: loyaltyAuth })

  const userTeams = useUserTeamsStore(user, firebase.firebaseDb)
  const role = userTeams.type === 'SUCCESS' ? userTeams.teams?.permissions : undefined

  const greeting = useGreeting()

  const isHomePage = useMemo(
    () => location.pathname === '/' || location.pathname.split('/').includes('admin'),
    [location.pathname],
  )

  const signOut = useCallback(async () => {
    await loyaltyAuth.signOut()
    navigate(PATH.HOME)
  }, [loyaltyAuth, navigate])

  const onClickGotoMerchant = () => {
    if (!role) return navigate(PATH.MERCHANT_CREATE)
    return role ? navigate(PATH.MERCHANT_DASHBOARD.replace(':id', Object.keys(role)[0])) : null
  }

  return (
    <div className={cn('shadow-sm', className)}>
      <header className='layout hidden items-center justify-between py-6 pr-3 md:flex'>
        <div className='flex items-end justify-start gap-x-4'>
          <div className='flex items-center gap-x-2'>
            <Link to='/'>
              <Logo
                aria-label='Logo'
                className='h-5'
              />
            </Link>
          </div>
          {user && (
            <nav className='flex items-end gap-x-4 leading-3'>
              <div
                className={clsx('text-light-secondary hover:text-blue-500', {
                  '!text-dark-main': location.pathname === '/',
                })}>
                <Link to='/'>Home</Link>
              </div>
              <Link
                to='/about'
                className='text-light-secondary hover:text-blue-500'>
                Settings
              </Link>
              <Link
                to='/contact'
                className='text-light-secondary hover:text-blue-500'>
                Support
              </Link>
            </nav>
          )}
        </div>
        {/* {user !== undefined && <SignIn />} */}
        <div>
          {user && (
            <div className='flex items-center gap-x-4'>
              {user && !user.isAnonymous && (
                <div
                  className='flex items-center gap-x-1 hover:cursor-pointer'
                  onClick={onClickGotoMerchant}>
                  <span className='text-csm capitalize'>
                    {role ? 'Merchant' : 'Create merchant'}
                  </span>
                  <ArrowRightIcon
                    height={10}
                    width={10}
                  />
                </div>
              )}

              <Popover>
                <PopoverTrigger>
                  <div className='flex items-center gap-x-1 hover:cursor-pointer'>
                    <UserCircleSVG
                      width={30}
                      height={30}
                    />
                    <span className='text-sm font-medium'>{user?.displayName}</span>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <div className='min-w-40 rounded-sm bg-white p-2 shadow-sm'>
                      <div
                        onClick={signOut}
                        className='flex w-full list-none items-center justify-between rounded-sm px-2 py-0.5 text-sm font-medium
                          !outline-none hover:cursor-pointer hover:bg-slate-200 focus-visible:!outline-none'>
                        <span>Sign out</span>
                        <FaSignOutAlt />
                      </div>
                    </div>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </div>
          )}
        </div>
      </header>
      <div className='hidden h-1 w-full bg-white lg:block'></div>
      {user && (
        <header
          className='layout sticky top-0 z-10 mt-3 flex items-center justify-between px-2.5 pb-2 shadow-sm md:hidden
            md:px-0'>
          {(isHomePage && (
            <div className='flex flex-col'>
              <span className='text-csm'>{greeting},</span>
              <span className='text-lg font-medium'>{user?.displayName}</span>
            </div>
          )) || (
            <div onClick={() => navigate(-1)}>
              <ChevronLeftIcon
                className='text-md font-bold'
                height={34}
                width={34}
                color={'black'}
              />
            </div>
          )}
          <div>
            {user?.photoURL || (
              <div onClick={toggleDrawer}>
                <UserCircleSVG
                  width={42}
                  height={42}
                />
              </div>
            )}
            <ProfileSettings
              isOpen={isDrawerOpen}
              onClose={toggleDrawer}
              title='Custom Drawer'
            />
          </div>
        </header>
      )}
    </div>
  )
}

export default Header
