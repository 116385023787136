import React from 'react';
import { ReactComponent as LogoWhite } from 'icons/logo-WhiteBg.svg'
import { cn } from './common/utils/tailwindUtils';

type PoweredByProps = {
  className?: string
}

const PoweredBy: React.FC<PoweredByProps> = ({ className }) => {
  return <div className={cn(`flex flex-col justify-center items-center gap-1 ${className}`)}>
    <span className="text-black">Powered by</span>
    <div className='bg-light-primary p-1 px-2.5'>
      <div className='max-w-24'><LogoWhite className='w-full' /></div>
    </div>
  </div>
}

export default PoweredBy