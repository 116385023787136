import { TwCardContainer } from 'components/modules/twComponent/twCard.module'
import { ReactComponent as HomeSVG } from 'icons/plus_circle.svg'

const AddMerchantCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className='block lg:hidden'>
      <TwCardContainer
        className='flex items-center w-20 min-w-20 h-20 justify-center border bg-white md:min-h-26 md:w-26 md:min-w-26
          xl:hover:cursor-pointer'>
        <div onClick={onClick}>
          <HomeSVG className='h-6 w-6 md:h-7 md:w-7' />
        </div>
      </TwCardContainer>
    </div>
  )
}
export default AddMerchantCard
