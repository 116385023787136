import { useCallback, useEffect, useRef } from "react"

/**
 * A hook that throttles state updates to the next animation frame.
 * Can be composed with existing state management hooks.
 * 
 * @param value The current state value
 * @param setValue The state setter function
 * @returns A throttled setter function
 */
export function useThrottled<T>(
  value: T,
  setValue: (value: T) => void
): [T, (value: T) => void] {
  const frameRef = useRef<number>()
  const pendingValueRef = useRef<T>()

  const throttledSetValue = useCallback((newValue: T) => {
    pendingValueRef.current = newValue
    
    if (!frameRef.current) {
      frameRef.current = requestAnimationFrame(() => {
        setValue(pendingValueRef.current as T)
        frameRef.current = undefined
      })
    }
  }, [setValue])

  useEffect(() => {
    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current)
      }
    }
  }, [])

  return [value, throttledSetValue]
} 