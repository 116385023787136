import { ButtonIcon } from "components/button/ButtonIcon";
import Transition from "components/Transition";
import { PATH } from "constants/routes";

interface RedeemPointSectionProps {
  navigate: (path: string) => void;
  title: string;
  message: string;
  icon?: string;
  isConfirm?: boolean;
  onClick?: () => void;
}

const RedeemPointSection: React.FC<RedeemPointSectionProps> = ({ navigate, onClick, title, message, icon, isConfirm }) => (
  <Transition>
    <div className="space-y-4">
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-2'>
          {icon && <img src={icon} alt={icon} className='w-6 h-6' />}
          <p className='font-semibold'>{title}</p>
        </div>
        <p className="pr-20">{message}</p>
      </div>
      {isConfirm && (
        <div className='flex justify-start gap-x-4'>
          <ButtonIcon
            type='button'
            content='Confirm'
            onClick={onClick}
            className='min-w-28 bg-light-primary text-white hover:brightness-110'
          />
          <ButtonIcon
            type='button'
            onClick={() => navigate(PATH.HOME)}
            content='Discard'
            className='min-w-28 border border-light-primary transition-colors duration-150 hover:bg-light-primary hover:text-white'
          />
        </div>
      )}
    </div>
  </Transition>
);

export default RedeemPointSection;