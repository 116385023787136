import NotFoundPage from 'NotFoundPage'
import ProtectedRoute from 'components/ProtectedRoute'
import { PATH } from 'constants/routes'
import HomePage from 'pages/Home'
import MerchantDashboard from 'pages/Home/components/MerchantDashboard'
import MerchantPage from 'pages/Merchant'
import MerchantCreate from 'pages/Merchant/components/MerchantCreate'
import ProgramEditor from 'pages/Program/ProgramEditor'
import ProgramPayment from 'pages/Program/ProgramPayment'
import Login from 'pages/SignIn'
import { CardPage } from 'pages/card/CardPage'
import { Route } from 'react-router-dom'
import { CustomerPage } from './CustomerPage'
import { Home } from './Home'
import { MerchantPage as MerchantTest } from './MerchantPage'
import { MerchantTeamInvitePage } from './MerchantTeamInvitePage'

export const routes = (
  <>
    <Route
      path={PATH.HOME}
      element={<ProtectedRoute component={HomePage} />}
    />
    <Route
      path={'/home'}
      element={<Home />}
    />
    <Route
      path={PATH.SIGN_IN}
      element={<Login />}
    />
    <Route
      path={PATH.MERCHANT_DASHBOARD}
      element={<MerchantDashboard />}
    />
    <Route
      path={PATH.PROGRAM_CREATE}
      element={<ProgramEditor />}
    />
    <Route
      path={PATH.PROGRAM_EDIT}
      element={<ProgramEditor isEdit />}
    />
    <Route
      path={PATH.PROGRAM_PAYMENT}
      element={<ProgramPayment />}
    />
    <Route
      path='/card/:id'
      element={<CardPage />}
    />
    <Route
      path='/customer/:customerId/:merchantId'
      element={<CustomerPage />}
    />
    <Route
      path='/customer/:customerId'
      element={<CustomerPage />}
    />
    <Route
      path='/merchant/:id/:configId'
      element={<MerchantTest />}
    />
    <Route
      path='/merchant/:id'
      element={<MerchantPage />}
    />
    <Route
      path={PATH.MERCHANT_CREATE}
      element={<MerchantCreate />}
    />
    <Route
      path={'/teaminvite/merchant/:id/:token'}
      element={<MerchantTeamInvitePage />}
    />
    <Route
      path='*'
      element={<NotFoundPage />}
    />
    {/* <Route
      path='/signin'
      element={<SignInPage />}
    /> */}
  </>
)
