import { MerchantQRCardV2 } from 'components/QRCode/MerchantQRCard'
import { FirebaseComponents } from 'components/common/Firebase'
import { cn } from 'components/common/utils/tailwindUtils'
import DropdownSelect, { DropDownOption } from 'components/dropdown'
import { User } from 'firebase/auth'
import { useMerchantData } from 'hooks/useMerchantData'
import { useEffect, useMemo, useState } from 'react'

interface MerchantAdressQRCardProps {
  addressStore?: Record<string, any>[]
  user: User
  firebase: FirebaseComponents
  merchantId: string
  cardConfigId: string
  isLaunched?: boolean
  className?: string
}

const MerchantAdressQRCard: React.FC<MerchantAdressQRCardProps> = ({
  user,
  firebase,
  merchantId,
  cardConfigId,
  isLaunched,
  className
}) => {
  const [addressIdSelected, setAddressIdSelected] = useState<string>()
  const merchantData = useMerchantData(merchantId)

  const cardConfigLocations: DropDownOption[] = useMemo(() => {
    return (
      merchantData?.merchant?.cardConfigs?.[cardConfigId]?.locations?.map((item) => ({
        value: item.id.toString(),
        label: item.address,
      })) || []
    )
  }, [cardConfigId, merchantData?.merchant?.cardConfigs])

  useEffect(() => {
    if (!addressIdSelected && cardConfigLocations.length > 0) {
      setAddressIdSelected(cardConfigLocations[0].value)
    }
  }, [addressIdSelected, cardConfigLocations])

  if (!merchantData) {
    return <></>
  }

  return (
    <div className={cn('bg-white p-4 shadow-md', className)}>
      {!isLaunched && (<div>
        <h2 className='mb-4 text-xl font-semibold'>Test your program</h2>
        <p>Use the QR code below to test how it all works.</p>
        <p>
          Once you launch your program, we&apos;ll send you a physical QR Code so customers can scan
          every time they make a purchase.
        </p>
      </div>)
        || <h2 className='mb-4 text-xl font-semibold'>My QR code</h2>}
      {user?.uid && (
        <div className='gap-y- 4 mt-4 flex flex-col gap-4'>
          {cardConfigLocations.length && addressIdSelected && (
            <DropdownSelect
              options={cardConfigLocations}
              label='Address'
              value={addressIdSelected}
              onChange={(value) => setAddressIdSelected(value)}
            />
          )}
          <MerchantQRCardV2
            configId={cardConfigId}
            merchantId={merchantId}
            firebase={firebase}
            user={user}
            qrUrl={`${window.location.origin}/merchant/${merchantId}?address=${addressIdSelected}&instorescan=true`}
          />
          {/* <a
            className='text-sm text-blue-600 underline'
            href={`${window.location.origin}/merchant/${user?.uid}?address=${addressIdSelected}`}>
            {window.location.origin}/merchant/{user?.uid}?address={addressIdSelected}
          </a> */}
        </div>
      )}
    </div>
  )
}
export default MerchantAdressQRCard
