import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { ButtonIcon } from 'components/button/ButtonIcon'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import CustomInput from 'components/input/CustomInput'
import { TwLableForm } from 'components/modules/twComponent/twCommon.module'
import { useQueryString } from 'components/QueryString'
import { PATH } from 'constants/routes'
import { updateProfile } from 'firebase/auth'
import { TwTitle } from 'pages/Home/twHome.module'
import { TwCard } from 'pages/SignIn/twSignIn.module'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useMerchantCreateStore, useUserTeamsStore } from 'UseMerchantStore'

interface MerchantFormProps {
  name: string
  businessName: string
}

const MerchantCreate: React.FC = () => {
  const navigate = useNavigate()
  const { auth, firebaseDb } = useFirebase(firebaseConfig)
  const { user } = useUserNullable({ auth: auth })
  const { createMerchant, isCreating } = useMerchantCreateStore(firebaseDb)
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const [redirectLocation] = useQueryString('redirect', '/')
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<MerchantFormProps>()

  // Todo create merchant for user
  const onSubmit = async (data: MerchantFormProps) => {
    if (!user) return
    try {
      const userId = user?.uid
      const hydratedUser = await auth.hydrateProfileIfRequired(user)

      //update user display name
      await updateProfile(hydratedUser, { displayName: data.name, })

      // waiting for merge PR to main branch
      await createMerchant(data.businessName, userId)

    } catch (error) {
      console.error('Error creating merchant:', error)
      reset()
    }
  }

  useEffect(() => {
    if (user?.displayName) {
      // entered user name previously in hydrate dialog in useUserNullable 
      setValue('name', user.displayName)
    }
  }, [setValue, user])

  useEffect(() => {
    if (userTeams.type === 'SUCCESS' && userTeams.teams?.permissions) {
      const role = userTeams.teams?.permissions
      const merchantId = role && Object.keys(role)[0] || ''
      if (merchantId) navigate(PATH.MERCHANT_DASHBOARD.replace(':id', merchantId) ?? redirectLocation)
    }
  }, [navigate, redirectLocation, userTeams])

  return (
    <div className='flex min-h-screen items-center justify-center lg:items-start lg:pt-32'>
      <TwCard>
        <div className='mb-6 flex items-center justify-center lg:hidden'>
          <Logo height={25} />
        </div>
        <TwTitle className='!mb-4'>Create Account</TwTitle>
        <p className='text-center mb-3'>Let&apos; set up an account, so you can start creating rewards programs</p>
        <div className='px-2'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TwLableForm>NAME</TwLableForm>
              <CustomInput
                label=''
                name='name'
                type='text'
                placeholder='Jiara Martins'
                error={errors.name}
                // onChangeCallback={(value) => handleInputChange('name', value)}
                control={control}
                rules={{ required: 'Name is required' }}
              />

            </div>
            <div>
              <TwLableForm>BUSINESS NAME</TwLableForm>
              <CustomInput
                label=''
                name='businessName'
                type='text'
                placeholder="Sally's Hair and Nail Salon"
                error={errors.businessName}
                control={control}
                rules={{ required: 'Business name is required' }}
              />

            </div>
            <ButtonIcon
              type='submit'
              disabled={isCreating}
              content={isCreating ? 'Creating...' :'Create Account'}
              className='w-full bg-light-primary text-white hover:brightness-110'
            />
          </form>
        </div>
      </TwCard>
    </div>
  )
}
export default MerchantCreate

