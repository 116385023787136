import { Button } from '@chakra-ui/react'
import { ButtonIcon } from 'components/button/ButtonIcon'
import Card from 'components/card/Card'
import { FirebaseDb, useFirebase } from 'components/common/Firebase'
import { cn } from 'components/common/utils/tailwindUtils'
import { PATH } from 'constants/routes'
import { ReactComponent as ShoppingCardSVG } from 'icons/shopping-cart.svg'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import { useProgramStore } from 'stores/programStore'
import { CardConfig, MerchantEntry, TokenCardConfig } from '../../../functions/src/data/common'

interface IProgramCard {
  merchant?: MerchantEntry
  mode: 'create' | 'edit' | 'preview'
  isPayment?: boolean
  merchantId?: string
  cardConfig: CardConfig | undefined
  cardConfigId: string | undefined
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
}
export const ProgramCardPanel: React.FC<IProgramCard> = (props) => {
  const { className, merchantId, cardConfig, cardConfigId, mode, isPayment } = props
  const location = useLocation()
  const navigate = useNavigate()
  const { programBody, setProgramBody } = useProgramStore()

  const { isLaunched, templates } = cardConfig ?? {}

  const { target, primaryColor, secondaryColor } =
    (cardConfig?.cardConfigType === 'tokens' &&
      templates &&
      cardConfig &&
      cardConfig.templates[cardConfig?.defaultTemplateId]) ||
    {}

  const {
    cardsConsumed = 0,
    cardsPurchased = 0,
    isUnlimited = false,
  } = (templates && cardConfig) || {}

  const isCardConfigAvailable = useMemo(
    () => (cardConfig && cardConfigId ? { cardConfig, cardConfigId } : undefined),
    [cardConfig, cardConfigId],
  )

  const handleGoPayment = useCallback(
    (cardConfigId: string) =>
      merchantId &&
      navigate(
        PATH.PROGRAM_PAYMENT.replace(':merchantId', merchantId).replace(
          ':cardConfigId',
          cardConfigId,
        ),
      ),
    [merchantId, navigate],
  )

  const handleEditNavigate = useCallback(
    () => merchantId && navigate(PATH.PROGRAM_EDIT.replace(':id', merchantId)),
    [navigate, merchantId],
  )

  const renderStatusBadge = useMemo(() => {
    if (isLaunched && merchantId && (mode === 'preview' || isCardConfigAvailable)) {
      return (
        <div className='flex items-center gap-x-2 rounded-xl bg-[#c1ff72] px-6 py-0.5 text-sm'>
          <p>Active</p>
        </div>
      )
    } else if (merchantId) {
      return (
        <div className='flex items-center gap-x-2 rounded-xl bg-yellow-400 px-1'>
          <ShoppingCardSVG className='h-5 w-5' />
          <p>Inactive</p>
        </div>
      )
    }
    return null
  }, [isLaunched, merchantId, mode, isCardConfigAvailable])

  const renderLaunchSection = useCallback(
    (props: { cardConfigId: string }) => (
      <div className='space-y-4 md:col-span-2'>
        <p className='text-light-secondary'>Your program is not active.</p>
        <p>Launch your program today to start rewarding your customers</p>
        <div className='flex justify-start gap-x-4'>
          <ButtonIcon
            type='submit'
            content='Test'
            className='min-w-28 border border-light-primary hover:bg-light-primary hover:text-white'
          />
          {!isPayment && (
            <ButtonIcon
              type='button'
              content='Launch'
              onClick={() => handleGoPayment(props.cardConfigId)}
              className='min-w-28 bg-light-primary text-white hover:brightness-110'
            />
          )}
        </div>
        {cardsConsumed !== null && cardsPurchased !== null && (
          <p className='text-sm'>
            <span className='rounded-xl bg-yellow-400 p-1 px-2 text-sm'>
              {isUnlimited ? 'Unlimited' : `${cardsConsumed}/${cardsPurchased + 10}`}
            </span>{' '}
            Test cards left
          </p>
        )}
      </div>
    ),
    [isPayment, handleGoPayment, cardsConsumed, cardsPurchased, isUnlimited],
  )

  const renderActiveSection = useMemo(
    () => (
      <>
        <div>
          <div className='my-10'>
            <p className='text-sm'>
              <span className='rounded-xl bg-[#c1ff72] p-1 px-4 text-sm'>
                {isUnlimited ? 'Unlimited' : `${cardsConsumed}/${cardsPurchased + 10}`}
              </span>{' '}
              Cards left
            </p>
          </div>
          {cardsConsumed > 10 && (
            <p className='text-[18px]'>Your digital loyalty cards are flying off the shelf! 🚀</p>
          )}
          {!isUnlimited && (
            <p className='text-[18px]'>Want peace of mind? Go Unlimited—no more running out!</p>
          )}
        </div>
        {!isPayment && !isUnlimited && (
          <div className='mt-4'>
            <ButtonIcon
              type='button'
              content='Go Unlimited'
              onClick={() =>
                merchantId &&
                cardConfigId &&
                navigate(
                  PATH.PROGRAM_PAYMENT.replace(':merchantId', merchantId).replace(
                    ':cardConfigId',
                    cardConfigId,
                  ),
                )
              }
              className='min-w-28 border bg-light-primary text-white transition-colors duration-150
                hover:border-light-primary hover:bg-transparent hover:text-light-primary'
            />
          </div>
        )}
      </>
    ),
    [cardConfigId, cardsConsumed, cardsPurchased, isPayment, isUnlimited, merchantId, navigate],
  )

  useEffect(() => {
    if (target) {
      setProgramBody({ ...programBody, reward: target })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]) // just update once when component mounted

  return (
    <div
      className={cn(
        'relative box-content h-min rounded-md bg-white p-4 pb-6 shadow-md lg:h-auto',
        className,
      )}>
      <div className='flex items-start justify-between'>
        <h2 className='mb-4 text-xl font-semibold'>Program</h2>
        {renderStatusBadge}
        {merchantId && <Button onClick={handleEditNavigate}>Edit</Button>}
      </div>
      <ProgramCard {...props} />
      {!isLaunched && mode === 'preview' && isCardConfigAvailable ?
        renderLaunchSection({ cardConfigId: isCardConfigAvailable.cardConfigId })
      : null}
      {isLaunched && mode === 'preview' && isCardConfigAvailable ? renderActiveSection : null}
    </div>
  )
}

export const ProgramCard: React.FC<IProgramCard> = ({
  merchant,
  cardConfig,
  cardConfigId,
  mode,
}) => {
  const navigate = useNavigate()
  const { programBody } = useProgramStore()
  const {
    name: nameBody,
    primaryColor: primaryColorBody,
    secondaryColor: secondaryColorBody,
    logo: logoBody,
    reward: targetBody,
    offer: offerBody,
    description: descriptionBody,
  } = programBody

  const { templates, description, logo, title } = cardConfig ?? {}

  const { target, offers, primaryColor, secondaryColor } =
    (cardConfig?.cardConfigType === 'tokens' &&
      templates &&
      cardConfig &&
      cardConfig.templates[cardConfig?.defaultTemplateId]) ||
    {}

  const isCardConfigAvailable = useMemo(
    () => (cardConfig && cardConfigId ? { cardConfig, cardConfigId } : undefined),
    [cardConfig, cardConfigId],
  )

  const finalTarget = targetBody ?? target ?? 0
  const finalOfferLabel = offerBody ?? offers?.[0]?.label
  const finalOffer = useMemo(
    () =>
      finalTarget && finalOfferLabel ? { cost: finalTarget, label: finalOfferLabel } : offers?.[0],
    [finalTarget, finalOfferLabel, offers],
  )
  const points = useMemo(() => {
    return Array.from({ length: finalTarget }, (_, i) => i + 1)
  }, [finalTarget])

  const onClickToCreate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    navigate(PATH.PROGRAM_CREATE)
  }

  return (
    <Card
      primaryColor={primaryColorBody ?? primaryColor ?? '#ECECEC'}
      secondaryColor={secondaryColorBody ?? secondaryColor ?? '#8E8D8D'}
      name={nameBody ?? title ?? undefined}
      offers={finalOffer}
      description={descriptionBody ?? description ?? undefined}
      logo={logoBody ?? logo ?? undefined}
      isCreate={mode === 'create'}
      onClick={onClickToCreate}
      markablePoints={points}
      hasData={!!isCardConfigAvailable}
      isPreview={mode === 'preview'}
    />
  )
}
export default ProgramCardPanel
