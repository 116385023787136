export const rgbToHex = ({
  r,
  g,
  b,
  a,
}: {
  r: number
  g: number
  b: number
  a?: number
}): string => {
  const toHex = (value: number) => value.toString(16).padStart(2, '0').toUpperCase()

  const hexR = toHex(r)
  const hexG = toHex(g)
  const hexB = toHex(b)
  const hexA = a !== undefined ? toHex(Math.round(a * 255)) : '' // Handle alpha if provided

  return `#${hexR}${hexG}${hexB}${hexA}`
}

export const hexToRgb = (hex: string) => {
  if (typeof hex !== 'string' || !hex.startsWith('#')) {
    return null
  }

  hex = hex.replace('#', '')

  // Handle shorthand hex (#RGB)
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  } else if (hex.length === 4) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  }

  if (hex.length !== 6 && hex.length !== 8) {
    return null
  }

  if (!/^[0-9A-F]{6,8}$/i.test(hex)) {
    return null
  }

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  const a = hex.length === 8 ? Math.round((parseInt(hex.slice(6, 8), 16) / 255) * 100) / 100 : 1

  return { r, g, b, a }
}

interface ColorWeight {
  color: string
  closenessWeight?: number
  measure?: 'b' | 'w' | 'h' | 'hw' | 'hb' | 'wb' | 'hwb' | string[]
}

function hexToHSL(hex: string): { h: number; s: number; l: number } | null {
  // Convert hex to RGB first
  const rgb = hexToRgb(hex)
  if (!rgb) return null

  // Convert RGB to HSL
  const r = rgb.r / 255
  const g = rgb.g / 255
  const b = rgb.b / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = 0
  let s = 0
  const l = (max + min) / 2

  if (max !== min) {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return { h: h * 360, s: s * 100, l: l * 100 }
}

function hexToHSV(hex: string): { h: number; s: number; v: number } | null {
  // Convert hex to RGB first
  const rgb = hexToRgb(hex)
  if (!rgb) return null

  // Convert RGB to HSV
  const r = rgb.r / 255
  const g = rgb.g / 255
  const b = rgb.b / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = 0
  let s = 0
  const v = max

  const d = max - min
  s = max === 0 ? 0 : d / max

  if (max !== min) {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return {
    h: h * 360, // Convert to degrees
    s: s * 100, // Convert to percentage
    v: v * 100, // Convert to percentage
  }
}

/**
 * Converts a hex color string to HWB (Hue, Whiteness, Blackness) color space.
 * HWB is a more intuitive color model that describes colors in terms of:
 * - Hue: The base color (0-360 degrees)
 * - Whiteness: How much white is mixed in (0-100%)
 * - Blackness: How much black is mixed in (0-100%)
 *
 * The conversion is done by first converting to HSV since HWB and HSV are directly related:
 * - Hue remains the same
 * - Whiteness = (100-Saturation) * Value/100
 * - Blackness = 100-Value
 *
 * @param hex - The hex color string to convert (e.g. "#FF0000")
 * @returns Object containing h, w, b values or {h:0, w:0, b:0} if invalid hex
 */
function hexToHWB(hex: string): { h: number; w: number; b: number } | null {
  // First convert to HSV since HWB is directly related to HSV
  const hsv = hexToHSV(hex)
  if (!hsv) return null
  // Calculate whiteness and blackness
  const w = ((100 - hsv.s) * hsv.v) / 100
  const b = 100 - hsv.v

  return {
    h: hsv.h, // Hue stays the same as HSV
    w: Math.round(w), // Whiteness percentage
    b: Math.round(b), // Blackness percentage
  }
}

export function colorDistance(primaryColor: string, compareColor: string | ColorWeight) {
  // Handle both string and ColorWeight inputs
  const compareHex = typeof compareColor === 'string' ? compareColor : compareColor.color
  const weight = typeof compareColor === 'string' ? 1 : (compareColor.closenessWeight ?? 1)
  const measure = typeof compareColor === 'string' ? 'hwb' : (compareColor.measure ?? 'hwb')

  // Convert both colors to HWB
  const hwb1 = hexToHWB(primaryColor)
  const hwb2 = hexToHWB(compareHex)

  if (!hwb1 || !hwb2) return 1 // Maximum distance if invalid colors

  // Calculate differences in HWB space
  const hDiff = Math.min(
    Math.min(Math.abs(hwb2.h - hwb1.h), 360 - Math.abs(hwb2.h - hwb1.h)) / 180,
    1,
  )
  const wDiff = Math.abs(hwb2.w - hwb1.w) / 100
  const bDiff = Math.abs(hwb2.b - hwb1.b) / 100

  // Hue importance decreases as whiteness or blackness increases
  const hueWeight = (1 - Math.max(hwb1.w, hwb1.b) / 100) * (1 - Math.max(hwb2.w, hwb2.b) / 100)

  // Get component weights based on measure
  let hWeight = 0,
    wWeight = 0,
    bWeight = 0

  if (Array.isArray(measure)) {
    // Custom weights provided as array [h,w,b]
    ;[hWeight, wWeight, bWeight] = measure.map(Number)
  } else {
    // Predefined measure combinations
    switch (measure) {
      case 'h':
        hWeight = 1
        break
      case 'w':
        wWeight = 1
        break
      case 'b':
        bWeight = 1
        break
      case 'hw':
        hWeight = 1
        wWeight = 1
        break
      case 'hb':
        hWeight = 1
        bWeight = 1
        break
      case 'wb':
        wWeight = 1
        bWeight = 1
        break
      default: // 'hwb'
        hWeight = 1
        wWeight = 1
        bWeight = 1
    }
  }

  // Calculate weighted distance using measure-specific weights
  const distance = Math.sqrt(
    Math.pow(hDiff * hueWeight * hWeight, 2) +
      Math.pow(wDiff * wWeight, 2) +
      Math.pow(bDiff * bWeight, 2),
  )

  // Normalize to 0-1 range based on maximum possible distance for these weights
  const maxDistance = Math.sqrt(
    Math.pow(hueWeight * hWeight, 2) + Math.pow(wWeight, 2) + Math.pow(bWeight, 2),
  )

  // Apply weight to bring result closer to extremes (0 or 1)
  const normalizedDistance = distance / maxDistance
  return Math.min(Math.max(Math.pow(normalizedDistance, weight || 1), 0), 1)
}

export function getClosestColor(primaryColor: string, colors: (string | ColorWeight)[]) {
  return colors.reduce((closest, color) => {
    return colorDistance(primaryColor, color) < colorDistance(primaryColor, closest) ?
        color
      : closest
  }, colors[0])
}
