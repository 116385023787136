export const PATH = {
  HOME: '/',
  SIGN_IN: '/signin',
  MERCHANT_DASHBOARD: '/merchant/:id/admin',
  PROGRAM_DETAIL: '/program/:id',
  PROGRAM_CREATE: '/program/create',
  PROGRAM_EDIT: '/program/edit/:id',
  PROGRAM_PAYMENT: '/program/payment/:merchantId/:cardConfigId',
  MERCHANT_CREATE: '/merchant/create',
}
