import clsx from 'clsx'
import { cn } from 'components/common/utils/tailwindUtils'
import tw from 'twin.macro'

interface IButtonIconProps {
  content: string
  icon?: string
  className?: string
  children?: React.ReactNode
  onClick?: (e: any) => void
  disabled?: boolean
  [other: string]: any
}

export const ButtonIcon: React.FC<IButtonIconProps> = ({
  content,
  icon,
  className,
  children,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <TwButton
      {...props}
      className={cn(className, clsx({ 'cursor-not-allowed brightness-90': disabled }))}
      disabled={disabled}
      onClick={onClick}>
      {icon && (
        <TwIcon
          alt={content}
          src={icon}
        />
      )}
      {content}
    </TwButton>
  )
}

export const TwButton = tw.button`flex items-center px-2 py-2 border rounded-md text-sm space-x-1 text-sm font-medium flex justify-center`
export const TwIcon = tw.img`h-5 w-5`
