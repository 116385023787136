import { CheckCircleIcon } from "@chakra-ui/icons"
import { Icon } from "@chakra-ui/react"
import { ButtonIcon } from "components/button/ButtonIcon"
import { PATH } from "constants/routes"
import { useNavigate } from "react-router-dom"

const PaymentSuccess: React.FC<{ email: string }> = ({ email }) => {
  const navigate = useNavigate()
  return <div className='animate-fade-right animate-once animate-ease-in relative col-span-12 box-content space-y-4 overflow-y-auto rounded bg-white shadow-md lg:col-span-4 p-4 pt-6'>
    <div className='space-y-2 font-medium'>
      <div className="flex items-center gap-2">
        <Icon as={CheckCircleIcon} color='green' boxSize={30} />
        <h2 className='animate-fade-right animate-once animate-ease-in text-xl font-semibold'>
          Success
        </h2>
      </div>
      <p className='text-light-secondary'>
        Great work on launching your program!
      </p>
      <div>
        <p className="font-semibold text-[17px] text-gray-800">Your program is now live!</p>
        <p className='text-light-secondary text-sm'>We&apos;re excited to let you know that you can now start to reward your customers using reclaim.</p>
      </div>
    </div>
    <div className="font-medium">
      <div className="mt-8"><p className="font-semibold text-[18px] text-gray-800">What happens next:</p></div>
      <ul className='text-gray-500 space-y-4'>
        <li>
          <p className="font-semibold text-gray-800">Confirmation</p>
          <span className="text-sm">
            {`We've sent a confirmation email to ${email} with all the details of your purchase.`}
          </span>
        </li>
        <li>
          <p className="font-semibold text-gray-800">Your starter pack is on its way</p>
          <span className="text-sm">A quick start package is included, which contains a physical QR banner. This is being prepared and you will receive tracking information shortly.</span>
        </li>
        <li>
          <p className="font-semibold text-gray-800">Start Reclaiming</p>
          <span className="text-sm">You can use the online QR code or print a temporary banner to start rewarding your customers now</span>
        </li>
      </ul>
    </div>
    <div className="flex justify-end">
      <ButtonIcon
        type='button'
        onClick={() => navigate(PATH.HOME)} //TODO: navigate to merchant dashboard
        content='My QR Code'
        className='min-w-28 bg-light-primary text-white hover:brightness-110 mt-22'
      />
    </div>
  </div>
}

export default PaymentSuccess