import { IProgramBody } from 'types/program.module'
import { create } from 'zustand'

interface IProgramStore {
  programBody: Partial<IProgramBody>
  setProgramBody: (event: Partial<IProgramBody>) => void
  clearFormData: () => void
}

const initialProgramBody: Partial<IProgramBody> = {
  reward: 10,
  offer: 'FREE',
}

const useProgramStore = create<IProgramStore>((set) => ({
  programBody: initialProgramBody,
  setProgramBody: (event: Partial<IProgramBody>) =>
    set((state) => ({
      programBody: { ...state.programBody, ...event }, // Merge partial updates into the state
    })),
  clearFormData: () => set({ programBody: {} }),
}))

export { useProgramStore }
