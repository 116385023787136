import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import ColorPickerComponent from 'components/ColorPicker'
import { ButtonIcon } from 'components/button/ButtonIcon'
import { CustomInputProps } from 'components/input/CustomInput'
import { ReactComponent as PlusSVG } from 'icons/icons-plus.svg'
import { TwAbsoluteCenter } from 'pages/Home/twHome.module'
import { useRef } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

const ColorPickerPopover = <T extends FieldValues>({
  control,
  onChangeCallback,
  name,
  label,
  defaultValue,
}: CustomInputProps<T>) => {
  const popoverContentRef = useRef<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className='flex flex-col items-center justify-center gap-y-2'>
          <Popover
            onClose={onClose}
            isOpen={isOpen}
            onOpen={onOpen}>
            <PopoverTrigger>
              <div
                className={`relative h-18 w-18 rounded-md border hover:cursor-pointer ${defaultValue || 'bg-slate-500'}`}
                style={{ backgroundColor: field.value }}>
                <TwAbsoluteCenter>
                  <PlusSVG
                    fill='#929292'
                    width={30}
                  />
                </TwAbsoluteCenter>
              </div>
            </PopoverTrigger>
            <PopoverContent
              as={Stack}
              className='rounded-md border bg-white p-3 px-5 shadow-lg focus-visible:outline-none'>
              <PopoverArrow />
              <PopoverBody>
                <ColorPickerComponent
                  {...field}
                  value={field.value || defaultValue || '#000000'}
                  onChange={(value) => {
                    onChangeCallback && onChangeCallback(value)

                    field.onChange(value)
                  }}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <p className='text-xs font-medium'>{label}</p>
        </div>
      )}
    />
  )
}
ColorPickerPopover.displayName = 'ColorPickerPopover'

export default ColorPickerPopover
