import { Spinner } from '@chakra-ui/react'
import { useUserTeamsStore } from 'UseMerchantStore'
import LocationAdditional from 'components/LocationsAdditional'
import Transition from 'components/Transition'
import { ButtonIcon } from 'components/button/ButtonIcon'
import Card from 'components/card/Card'
import { showDialog } from 'components/common/Dialog'
import { useFirebase } from 'components/common/Firebase'
import { useUserNullable } from 'components/common/UseUser'
import CustomInput from 'components/input/CustomInput'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import SkeletonHome from 'components/skeletons/HomeSkeleton'
import { LOCATION } from 'constants/common'
import { PATH } from 'constants/routes'
import { Product } from 'data/stripe/StripeProduct'
import { set } from 'firebase/database'
import { useMerchantData } from 'hooks/useMerchantData'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { firebaseConfig } from 'services/FirebaseConfig'
import NotificationPanel from 'ui/NotificationPanel'
import { getCheckoutSession, getPayments, getProducts } from 'util/firestoreUtil'
import { loadCheckout } from 'util/loadCheckout'
import { usePromiseState } from 'util/reactutils'
import {
  ILocation,
  Metadata,
  PaymentMetadata,
  TokenCardConfig,
} from '../../../functions/src/data/common'
import ProgramCardPanel, { ProgramCard } from './ProgramCard'
import { OptionPaymentItem } from './components/OptionPaymentItem'
import PaymentSuccess from './components/PaymentSuccess'

type ProgramPaymentProps = {
  name?: string
  email?: string
  plan: string
  location: string
}

const ProgramPayment: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const location = useLocation()
  const { merchantId, cardConfigId } = useParams()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const successParam = searchParams.get('success')
  const canceledParam = searchParams.get('canceled')
  const orderIdParam = searchParams.get('orderId')

  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const { user } = useUserNullable({ auth: firebase.auth })
  const merchantData = useMerchantData(merchantId)
  const userTeams = useUserTeamsStore(user, firebaseDb)
  const products = usePromiseState(
    async () => getProducts(firebase.firestoreDb),
    [firebase.firestoreDb],
  )

  const productsData = useMemo(
    () =>
      products?.filter(
        (item) => item.metadata && !Object.keys(item.metadata).includes('locations'),
      ),
    [products],
  )
  const locationPrices = useMemo(
    () =>
      products
        ?.filter((item) => item.metadata && Object.keys(item.metadata).includes('locations'))
        .firstOrNull(),
    [products],
  )

  const [selectedPlan, setSelectedPlan] = useState<Product | null>(
    (products && products?.length > 0 && products?.firstOrNull()) || null,
  )
  const [locatoionsAdditioncal, setLocatoionsAdditioncal] = useState<ILocation[]>([])
  const [locations, setLocaltions] = useState<ILocation[]>([])
  const [countAdditionalLocations, setCountAdditionalLocations] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [checkouLoading, setCheckoutLoading] = useState<boolean>(false)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false)
  const [errorPlan, setErrorPlan] = useState<string | null>(null)

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    getValues,
    setValue,
    setError,
  } = useForm<ProgramPaymentProps>()

  //create order id by merchant id and timestamp
  const orderId = useMemo(() => {
    if (selectedPlan) {
      const timestamp = new Date().getTime()
      return `${selectedPlan.id}_${timestamp}`
    }
    return ''
  }, [selectedPlan])

  const cardConfig = useMemo(
    () => (cardConfigId ? merchantData?.merchant?.cardConfigs?.[cardConfigId] : undefined),
    [cardConfigId, merchantData?.merchant?.cardConfigs],
  )

  const onSubmit = handleSubmit(async (data) => {
    if (!selectedPlan) {
      setErrorPlan('Please select a plan')
      return
    }
    const totalPayment =
      (selectedPlan?.prices?.firstOrNull()?.unit_amount ?? 0) / 100 +
      countAdditionalLocations * LOCATION.LOCATION_COST
    const price = selectedPlan?.prices.firstOrNull()

    const paymentBody: PaymentMetadata = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: data.name!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: data.email!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      plan: selectedPlan?.id,
      unitAmount: totalPayment,
      countAdditionalLocations: countAdditionalLocations,
      orderId: orderId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      merchantId: merchantId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      cardConfigId: cardConfigId!,
      locationsJson: JSON.stringify(locatoionsAdditioncal),
    }

    if (user && paymentBody.unitAmount && price) {
      const lineItems = [
        {
          price: price.id,
          quantity: 1,
        },
      ]

      if (countAdditionalLocations > 0 && locationPrices) {
        const locationPriceId = locationPrices.prices.firstOrNull()?.id
        if (locationPriceId) {
          lineItems.push({
            price: locationPriceId,
            quantity: countAdditionalLocations,
          })
        }
      }
      setCheckoutLoading(true)
      await loadCheckout({
        firebaseDb: firebaseDb,
        firestoreDb: firebase.firestoreDb,
        price: price,
        user: user,
        metadata: paymentBody,
        lineItems: lineItems,
      })
      setCheckoutLoading(false)
    }
    return null
  })

  const handleClearLocation = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    e.preventDefault()
    if (locations.length > 0) {
      const paidLocations = locations.find((item) => item.id === id && item.isPaid)
      if (paidLocations) {
        showDialog({
          title: 'Location is paid',
          children: 'This location is paid, you cannot remove it!',
          positiveButtonProps: {
            text: 'OK',
            onClicked: () => {
              return true
            },
          },
        })
        return
      }
      const newAdress = locations.filter((item) => item.id !== id)
      const unpaidLocations = newAdress.filter((item) => !item.isPaid)
      setCountAdditionalLocations(unpaidLocations.length)
      setLocatoionsAdditioncal(unpaidLocations)
      setLocaltions(newAdress)
    }
  }

  const handleAddLocation = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      const newLocation = getValues('location')
      const isExisted =
        newLocation &&
        locations.find(
          (item) => item.address.toLocaleLowerCase() === newLocation.toLocaleLowerCase(),
        )

      if (isExisted) {
        setError('location', { type: 'value', message: 'Store location is already existed!' })
        return
      }

      if (newLocation) {
        const lastItem = locations[locations.length - 1]
        const newId = lastItem ? lastItem.id + 1 : 1
        const mergeAddress = [
          ...locations,
          {
            id: newId,
            address: newLocation,
            isPaid: false,
          },
        ] as ILocation[]

        const unpaidLocations = mergeAddress.filter((item) => !item.isPaid)

        setCountAdditionalLocations(unpaidLocations.length)
        setLocatoionsAdditioncal(unpaidLocations)
        setLocaltions(mergeAddress)
        clearErrors('location')
        setValue('location', '')
      } else {
        setError('location', { type: 'value', message: 'Store location is empty!' })
      }
    },
    [getValues, locations, setError, clearErrors, setValue],
  )

  const metaExtra = useMemo(() => {
    return selectedPlan?.metadata && Object.keys(selectedPlan.metadata).length > 0 ?
        `for ${Object.values(selectedPlan.metadata).firstOrNull()} digital cards`
      : selectedPlan?.name
  }, [selectedPlan])

  useEffect(() => {
    if (user) {
      setValue('name', user.displayName ?? undefined)
      setValue('email', user.email ?? undefined)
    }

    if (cardConfig && cardConfig.locations) {
      const locations = cardConfig.locations.map((item, index) => ({
        ...item,
        isPaid: true,
      }))
      setLocaltions(locations)
    }
  }, [cardConfig, setValue, user])

  useEffect(() => {
    const storedOrderIds = JSON.parse(sessionStorage.getItem('checkedOrderIds') || '[]')

    if (storedOrderIds.includes(orderIdParam) && merchantId && cardConfigId) {
      setLoading(false)
      navigate(
        PATH.PROGRAM_PAYMENT.replace(':merchantId', merchantId).replace(
          ':cardConfigId',
          cardConfigId,
        ),
      )
      return
    }

    if (!canceledParam && !successParam) {
      setLoading(false)
      return
    }

    if (canceledParam) {
      setLoading(false)
      return
    }
    if (merchantId && user?.uid && orderIdParam && successParam) {
      getCheckoutSession(firebase.firestoreDb, user.uid)
        .then((checkout) => {
          const isHasPaidProduct = checkout?.firstOrNull()?.metadata?.orderId === orderIdParam
          if (isHasPaidProduct) {
            setIsPaymentSuccess(true)
            setLoading(false)
          } else {
            setLoading(false)
          }
          storedOrderIds.push(orderIdParam)
          sessionStorage.setItem('checkedOrderIds', JSON.stringify(storedOrderIds))
        })
        .catch((error) => {
          console.error('Error getting document:', error)
          setLoading(false)
        })
    }
  }, [
    merchantId,
    orderIdParam,
    successParam,
    canceledParam,
    user,
    firebase.firestoreDb,
    navigate,
    cardConfigId,
  ])

  if (!userTeams.teams && !user && !merchantId) {
    return <></>
  }

  if (loading) {
    return <SkeletonHome />
  }

  return (
    <section className='layout bg-light-mb-main pb-14'>
      <TwMainGridWrapper className='layout min-h-full'>
        <NotificationPanel
          user={user || undefined}
          firebaseDb={firebaseDb}
          merchantId={merchantId}
          className='col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block'
        />
        {cardConfig && (
          <div className='col-span-12 !mt-8 flex h-40 w-full flex-col items-center md:hidden '>
            <ProgramCard
              cardConfig={cardConfig}
              merchant={merchantData?.merchant}
              merchantId={merchantId}
              cardConfigId={cardConfigId}
              isPayment={
                !(isPaymentSuccess && successParam && user?.email && !cardConfig?.isUnlimited)
              }
              mode='preview'
            />
          </div>
        )}

        {cardConfig && (
          <ProgramCardPanel
            className='col-span-12 !mt-8 hidden md:col-span-5 md:block lg:col-span-5'
            cardConfig={cardConfig}
            merchant={merchantData?.merchant}
            merchantId={merchantId}
            cardConfigId={cardConfigId}
            isPayment={
              !(isPaymentSuccess && successParam && user?.email && !cardConfig?.isUnlimited)
            }
            mode='preview'
          />
        )}
        {!cardConfig && <Spinner className='absolute left-0 right-0 top-1/3 m-auto h-32 w-32' />}
        {isPaymentSuccess && successParam && user?.email && <PaymentSuccess email={user.email} />}
        {!(isPaymentSuccess && successParam && user?.email) && cardConfig && (
          <div
            className='animate-fade-right animate-once animate-ease-in relative col-span-12 box-content space-y-4 rounded
              bg-white shadow-md md:col-span-7 lg:col-span-4 xl:overflow-y-auto'>
            <div className='sticky top-0 z-10 flex items-start justify-between bg-white p-4'>
              <div className='space-y-2'>
                <h2 className='animate-fade-right animate-once animate-ease-in text-xl font-semibold'>
                  Launch your program
                </h2>
                <p className='text-light-secondary'>
                  To launch your loyalty program, please proceed to payment
                </p>
              </div>
            </div>
            <div className='p-4 pt-0'>
              <form
                ref={formRef}
                onSubmit={onSubmit}
                className='pb-4'>
                <div className='lg:space-x-6'>
                  <div className='col-span-2 space-y-4'>
                    <CustomInput
                      label='Full name'
                      name='name'
                      type='text'
                      error={errors.name}
                      control={control}
                      rules={{ required: 'Name your program is required' }}
                    />

                    <CustomInput
                      label='Email Address'
                      name='email'
                      type='text'
                      error={errors.email}
                      control={control}
                      rules={{ required: 'Email is required' }}
                    />

                    <div className='space-y-4'>
                      {productsData?.map((item) => {
                        const extra =
                          item.metadata && Object.keys(item.metadata).length > 0 ?
                            `for ${item.metadata.type === 'one_time' ? 'unlimited' : item.metadata.digital_cards} digital cards`
                          : item.name
                        return (
                          <OptionPaymentItem
                            key={item.id}
                            price={(item.prices.firstOrNull()?.unit_amount ?? 0) / 100}
                            title={item.name}
                            description={item.description}
                            extra={extra}
                            onClick={() => {
                              setSelectedPlan(item), setErrorPlan(null)
                            }}
                            isActive={selectedPlan?.id === item.id}
                          />
                        )
                      })}
                      {errorPlan && (
                        <span className='text-xs italic text-red-500'>{errorPlan}</span>
                      )}
                    </div>

                    <div className='w-auto space-y-3 text-sm'>
                      <p className='text-[1.35rem] font-semibold'>Extras Locations</p>
                      <p className='text-gray-500'>
                        Whether you choose the One-time Payment Plan or the Pay-As-You-Go Plan, you
                        can easily expand your program to cover more business locations.
                      </p>
                      <ul className='list-disc pl-6 text-gray-500'>
                        <li>
                          <b>Included:</b> {LOCATION.FREE_LOCATION_NUMBER} location
                        </li>
                        <li>
                          <b>Extra locations:</b> ${LOCATION.LOCATION_COST} per additional location
                        </li>
                      </ul>
                    </div>

                    <div className='relative'>
                      <LocationAdditional
                        locations={locations}
                        name='location'
                        onClick={handleClearLocation}
                        addLocations={handleAddLocation}
                        errors={errors.location}
                        control={control}
                      />
                    </div>
                    {locations.length > LOCATION.FREE_LOCATION_NUMBER &&
                      countAdditionalLocations > 0 && (
                        <Transition>
                          <OptionPaymentItem
                            className='px-3'
                            price={countAdditionalLocations * LOCATION.LOCATION_COST}
                            title='Additional Locations'
                            description={
                              <span>
                                {locations.length - LOCATION.FREE_LOCATION_NUMBER}x Additional
                                Locations added{' '}
                                <span className='whitespace-nowrap text-gray-500'>
                                  (${LOCATION.LOCATION_COST} each)
                                </span>
                              </span>
                            }
                            isSelectIcon={false}
                          />
                        </Transition>
                      )}

                    {selectedPlan && (
                      <>
                        <div className='space-y-4'>
                          <p className='py-2 text-[1.35rem] font-semibold'>Summary</p>
                          <div>
                            <div className='flex items-center justify-between font-semibold'>
                              <p>Plan selected</p>
                              <p>${(selectedPlan.prices.firstOrNull()?.unit_amount ?? 0) / 100}</p>
                            </div>
                            <p className='text-sm text-gray-500'>{metaExtra}</p>
                          </div>
                          {countAdditionalLocations > 0 && (
                            <Transition>
                              <div className='flex items-center justify-between font-semibold'>
                                <p>Additional locations</p>
                                <p>{`$${countAdditionalLocations * LOCATION.LOCATION_COST}`}</p>
                              </div>
                              <div className='text-sm'>
                                <p className='text-gray-500'>{`${countAdditionalLocations}x Additional locations added`}</p>
                                <p className='text-gray-500'>{`${LOCATION.FREE_LOCATION_NUMBER}x included locations (free)`}</p>
                              </div>
                            </Transition>
                          )}
                        </div>
                        <div>
                          <div className='h-0.5 w-full bg-gray-400' />
                          <div className='flex items-center justify-between py-2 text-2xl font-bold'>
                            <p>Total:</p>
                            <p>{`$${(selectedPlan.prices.firstOrNull()?.unit_amount ?? 0) / 100 + countAdditionalLocations * LOCATION.LOCATION_COST}`}</p>
                          </div>
                          <div className='h-0.5 w-full bg-gray-400' />
                          <p className='mt-3 text-sm text-gray-500'>
                            By clicking the “Proceed to Payment” button below you agree to our Terms
                            of Service and Privacy Policy.
                          </p>
                        </div>
                      </>
                    )}
                    <div className='flex justify-end gap-x-4 pt-4'>
                      <ButtonIcon
                        type='submit'
                        content='Proceed to Payment'
                        className='min-w-28 bg-light-primary text-white hover:brightness-110 disabled:opacity-30'
                        disabled={checkouLoading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </TwMainGridWrapper>
    </section>
  )
}

export default ProgramPayment
