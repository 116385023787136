export const LOCATION = {
  FREE_LOCATION_NUMBER: 1,
  LOCATION_COST: 49
}

export enum MENU_BOTTOM_TYPE {
  HISTORY = 'HISTORY',
  MY_CARDS = 'MY_CARDS',
  QR_CODE = 'QR_CODE',
  NOTIFICATION = 'NOTIFICATION',
  HOME = 'HOME'
}