import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { useMerchantPrivateStore, useMerchantStore } from 'UseMerchantStore'
import { showDialog } from 'components/common/Dialog'
import { FirebaseDb } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import {
  LoyaltyCard,
  PointLoyaltyCardProps,
  ScratchyLoyaltyCardProps,
  TokenLoyaltyCardProps,
} from 'pages/card/LoyaltyCard'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { UnreachableError } from 'util/error'
import checkIcon from '../../icons/crown_icon.svg'
import RedeemPointSection from './components/RewardEarnedSection'

type MessageNotification = {
  message: string
  icon: string
  title: string
  type: 'reward' | 'point'
}

export function LiveLoyaltyCard(props: {
  id: string
  merchantId: string | undefined
  firebaseDb: FirebaseDb
  user: User | undefined | null
}) {
  const tokenRef = useRef<number>(0)
  const [merchant, setMerchant] = useState(props.merchantId)
  const [messageConfirm, setMessageConfirm] = useState<MessageNotification>()
  const [sessionMarkedPoints, setSessionMarkedPoints] = useState<number>(0)

  const navigate = useNavigate()
  const state = useLoyaltyCardStore(props.id, props.firebaseDb)
  const { type: permissionResult } = useMerchantPrivateStore(merchant, props.user, props.firebaseDb)

  const balanceState = useMemo(() => {
    if (state.type === 'SUCCESS' && state.cardData) {
      let sessionMarkedPointsDelta = sessionMarkedPoints

      const { bankType, templateId, balance, name } = state.cardData

      if (bankType === 'tokens') {
        const tokenBalance = templateId ? balance?.[templateId] : null

        if (tokenBalance?.marked && tokenBalance.marked > 0) {
          sessionMarkedPointsDelta = sessionMarkedPoints - tokenBalance.marked
        }

        return {
          name,
          marked: tokenBalance?.marked || 0,
          lineMax: tokenBalance?.lineMax || 0,
          target: tokenBalance?.target || 0,
          claimed: tokenBalance?.claimed || 0,
          offers: tokenBalance?.offers,
          templateId: templateId,
          sessionMarkedPointsDelta: sessionMarkedPointsDelta,
        }
      }
    }
    return { name: undefined, marked: 0, lineMax: 0, target: 0, templateId: undefined } // Default return value to avoid undefined
  }, [sessionMarkedPoints, state])

  const showClaimConfirmationDialog = useCallback(
    (cardTypeProps: TokenLoyaltyCardProps, marked: number = balanceState.marked) => {
      if (cardTypeProps.onClaimClicked && balanceState.name) {
        if (cardTypeProps?.type === 'tokens' && cardTypeProps.onClaimClicked && balanceState.name) {
          const offers = Object.entries(balanceState.offers ?? {})
            .flatMap(([_, offer]) => ({ cost: offer.cost, label: offer.label }))
            .filter((offer) => offer.cost <= marked)
            .firstOrNull()

          if (offers) {
            showDialog({
              title: 'Claim Reward',
              children: 'Are you sure the customer wants to claim the reward?',
              positiveButtonProps: {
                text: 'Claim',
                onClicked: () => {
                  cardTypeProps?.onClaimClicked?.(
                    offers.label,
                    balanceState.templateId,
                    offers.cost,
                  )
                  setMessageConfirm({
                    icon: '/images/firework.png',
                    title: 'Reward Earned!',
                    message: `Success! ${balanceState.name} has completed their card.`,
                    type: 'reward',
                  })
                  return true
                },
              },
              negativeButtonProps: {
                text: 'Cancel',
                onClicked: () => {
                  return true
                },
              },
            })
          }
        }
      }
    },
    [balanceState.name, balanceState.offers, balanceState.marked, balanceState.templateId],
  )

  const onClickClaim = useCallback(
    (cardTypeProps: TokenLoyaltyCardProps) => {
      if (balanceState.templateId && cardTypeProps.updateBalance) {
        cardTypeProps.updateBalance(
          balanceState.templateId,
          (marked) => marked + (balanceState.sessionMarkedPointsDelta ?? 0),
        )
        setSessionMarkedPoints(0)
        showClaimConfirmationDialog(
          cardTypeProps,
          balanceState.marked + (balanceState.sessionMarkedPointsDelta ?? 0),
        )
      }
    },
    [
      balanceState.marked,
      balanceState.sessionMarkedPointsDelta,
      balanceState.templateId,
      showClaimConfirmationDialog,
    ],
  )

  const onConfirmPointsChange = useCallback(
    async (cardTypeProps: TokenLoyaltyCardProps) => {
      if (cardTypeProps.updateBalance && balanceState.templateId) {
        cardTypeProps.updateBalance(
          balanceState.templateId,
          (marked) => marked + balanceState.sessionMarkedPointsDelta,
        )
        setSessionMarkedPoints(0)

        setMessageConfirm({
          icon: '/images/firework.png',
          title: 'Point redeemed!',
          message: `Success! ${balanceState.name} has received ${balanceState.sessionMarkedPointsDelta} point.`,
          type: 'point',
        })

        if (sessionMarkedPoints >= balanceState.target) {
          showDialog({
            title: () => (
              <span className='flex flex-nowrap gap-2'>
                <img
                  src='/images/firework.png'
                  className='inline-block h-8 w-8'
                />
                Reward Earned!
              </span>
            ),
            children: `${balanceState.name} has completed their rewards card. Ready to redeem their gift?`,
            positiveButtonProps: {
              text: 'Claim',
              onClicked: () => {
                showClaimConfirmationDialog(
                  cardTypeProps,
                  balanceState.marked + (balanceState.sessionMarkedPointsDelta ?? 0),
                )
                return true
              },
            },
            negativeButtonProps: {
              text: 'Not now',
              onClicked: () => {
                return true
              },
            },
          })
        }
      }
    },
    [
      balanceState.templateId,
      balanceState.name,
      balanceState.sessionMarkedPointsDelta,
      balanceState.target,
      balanceState.marked,
      sessionMarkedPoints,
      showClaimConfirmationDialog,
    ],
  )

  const cardTypeProps:
    | PointLoyaltyCardProps
    | TokenLoyaltyCardProps
    | ScratchyLoyaltyCardProps
    | undefined = useMemo(() => {
    if (state.type !== 'SUCCESS') return undefined
    if (state.cardData.merchant !== merchant) setMerchant(state.cardData.merchant)
    const cardData = state.cardData
    const loyaltyType = cardData.bankType
    switch (loyaltyType) {
      case 'tokens':
        return {
          type: loyaltyType,
          bank: cardData,
          updateBalance: (tokenType, update) => {
            state.updateTokenBalance?.(tokenType, (balance) => ({
              ...balance,
              marked: update(balance.marked ?? 0),
            }))
          },
          onClaimClicked: (offerLabel, tokenType, cost) =>
            state.updateTokenBalance?.(tokenType, (balance) => ({
              ...balance,
              marked: (balance.marked ?? 0) - cost,
              claimed: (balance.claimed ?? 0) + cost,
            })),
        } satisfies TokenLoyaltyCardProps
      case 'points':
        return {
          type: loyaltyType,
          card: cardData,

          onClaimClickConfirmed: (offerLabel, pointType, cost) =>
            state.updatePointBalance?.(pointType, (balance) => ({
              ...balance,
              points: (balance.points ?? 0) - cost,
              claimed: (balance.claimed ?? 0) + cost,
            })),
          onManualEntrySubmitted: (
            pointType: string,
            operation: 'give' | 'take',
            amount: number,
          ) => {
            if (operation === 'take') {
              state.updatePointBalance?.(pointType, (balance) => ({
                ...balance,
                points: (balance.points ?? 0) - amount,
                claimed: (balance.claimed ?? 0) + amount,
              }))
            } else if (operation === 'give') {
              state.updatePointBalance?.(pointType, (balance) => ({
                ...balance,
                points: (balance.points ?? 0) + amount,
              }))
            }
          },
        } satisfies PointLoyaltyCardProps
      case 'scratchy':
        return {
          type: loyaltyType,
          bank: cardData,
        } satisfies ScratchyLoyaltyCardProps
      default:
        throw UnreachableError(loyaltyType)
    }
  }, [state, merchant])

  //set marked points
  useEffect(() => {
    if (state.type === 'SUCCESS') {
      if (sessionMarkedPoints < balanceState.marked || balanceState.marked === 0) {
        setSessionMarkedPoints(balanceState.marked)
        tokenRef.current = balanceState.marked
      }

      if (!balanceState.marked || balanceState.marked === 0) {
        setSessionMarkedPoints(0)
        tokenRef.current = 0
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, balanceState.marked]) // just run once

  useEffect(() => {
    if (state.type === 'SUCCESS' && !merchant) {
      setMerchant(state.cardData.merchant)
    }
  }, [merchant, state])

  useEffect(() => {
    if (tokenRef.current !== sessionMarkedPoints) {
      setMessageConfirm(undefined)
      tokenRef.current = sessionMarkedPoints
    }
  }, [sessionMarkedPoints])

  useEffect(() => {
    const messageTimeout = setTimeout(() => {
      setMessageConfirm(undefined)
    }, 5000)

    return () => clearTimeout(messageTimeout)
  }, [messageConfirm])

  return (
    <>
      {state.type === 'SUCCESS' &&
        cardTypeProps &&
        balanceState.templateId &&
        cardTypeProps.type === 'tokens' && (
          <>
            <LoyaltyCard
              {...cardTypeProps}
              key={'loyalty-card'}
              templateId={balanceState.templateId}
              cardConfigId={state.cardData.cardConfigId}
              cardTitle={state.cardData.title ?? undefined}
              fontFamily={'LeagueSpartan'}
              theme={{ backgroundColor: 'black' }}
              logo={state.cardData.logo || undefined}
              markedIcon={checkIcon}
              qrUrl={`${window.location.origin}/card/${props.id}?merchant=${state.cardData.merchant}`}
              editable={permissionResult === 'SUCCESS' && state.cardData.owner !== props.user?.uid}
              setSessionMarkedPoints={setSessionMarkedPoints}
              sessionMarkedPoints={sessionMarkedPoints}
              onClickClaim={() => onClickClaim(cardTypeProps)}
            />

            {cardTypeProps.type === 'tokens' &&
              permissionResult === 'SUCCESS' &&
              state.cardData.owner !== props.user?.uid && (
                <>
                  {!!balanceState.sessionMarkedPointsDelta &&
                    (sessionMarkedPoints || balanceState.marked) <= balanceState.target &&
                    !messageConfirm && (
                      <RedeemPointSection
                        navigate={navigate}
                        icon='/images/bell.png'
                        title='Redeem point?'
                        isConfirm={true}
                        onClick={() => onConfirmPointsChange(cardTypeProps)}
                        message={`You're about to redeem ${balanceState.sessionMarkedPointsDelta} point for ${balanceState.name}. Confirm?`}
                      />
                    )}

                  {messageConfirm && (
                    <RedeemPointSection
                      navigate={navigate}
                      isConfirm={false}
                      icon={messageConfirm.icon}
                      title={messageConfirm.title}
                      message={messageConfirm.message}
                    />
                  )}
                </>
              )}
          </>
        )}
      {(state.type === 'NO_CARD' ||
        (state.type === 'FAIL' && state.error.code === 'PERMISSION_DENIED')) &&
        props.user &&
        merchant && <Navigate to={`/merchant/${merchant}`} />}
      {state.type === 'FAIL' && state.error.code !== 'PERMISSION_DENIED' && (
        <>Something went wrong. Try again later...</>
      )}
      {state.type === 'PENDING' && <>Loading card details...</>}
      {!balanceState.templateId && <>No card found...</>}
    </>
  )
}
