import { MENU_BOTTOM_TYPE } from 'constants/common'
import { create } from 'zustand'

interface MenuBottomTypeProps {
  menuBottomType: MENU_BOTTOM_TYPE,
  setMenuBottomType: (event: MENU_BOTTOM_TYPE) => void
}

const useMenuBottomType = create<MenuBottomTypeProps>((set) => ({
  menuBottomType: MENU_BOTTOM_TYPE.HOME,
  setMenuBottomType: (event: MENU_BOTTOM_TYPE) =>
    set(() => ({
      menuBottomType: event,
    })),
}))

export { useMenuBottomType }
