import { FirebaseError } from 'firebase/app'
import { useMerchantData } from 'hooks/useMerchantData'
import { useMemo } from 'react'
import {
  ActiveLoyaltyCardEntry,
  PointBalance,
  ScratchyBalance,
  TokenBalance,
} from '../functions/src/data/common'
import { FirebaseDb, useDatabaseNullableRefLiveValue } from './components/common/Firebase'

export type LoyaltyCardState =
  | {
      type: 'SUCCESS'
      cardData: ActiveLoyaltyCardEntry
      updateTokenBalance: (
        templateId: `${keyof ActiveLoyaltyCardEntry['balance']}`,
        fn: (value: TokenBalance) => TokenBalance,
      ) => void
      updatePointBalance: (
        templateId: `${keyof ActiveLoyaltyCardEntry['balance']}`,
        fn: (value: PointBalance) => PointBalance,
      ) => void
      updateScratchyBalance: (
        templateId: `${keyof ActiveLoyaltyCardEntry['balance']}`,
        fn: (value: ScratchyBalance) => ScratchyBalance,
      ) => void
    }
  | { type: 'FAIL'; error: FirebaseError }
  | { type: 'NO_CARD' }
  | { type: 'PENDING' }

export function useLoyaltyCardStore(id: string | null, firebaseDb: FirebaseDb): LoyaltyCardState {
  const ref = useMemo(() => {
    if (id === null) {
      return
    }
    return firebaseDb.getRef(`/cards/${id}`)
  }, [id, firebaseDb])

  const cardData = useDatabaseNullableRefLiveValue<ActiveLoyaltyCardEntry>({ ref })
  const result = useMemo<LoyaltyCardState>(() => {
    if (cardData instanceof Error) {
      return { type: 'FAIL', error: cardData as FirebaseError }
    } else if (cardData === null) {
      return { type: 'NO_CARD' }
    } else if (cardData === undefined) {
      return { type: 'PENDING' }
    } else {
      return {
        type: 'SUCCESS',
        cardData,
        updateTokenBalance: async (
          templateId: `${keyof (typeof cardData)['balance']}`,
          fn: (value: TokenBalance) => TokenBalance,
        ) => {
          await firebaseDb
            .getRef(`/cards/${id}/balance/${templateId}`) //warning: Implicit conversion of a 'symbol' to a string
            .runTransaction((data: TokenBalance) => fn(data))
        },
        updatePointBalance: async (
          templateId: keyof (typeof cardData)['balance'],
          fn: (value: PointBalance) => PointBalance,
        ) => {
          await firebaseDb
            .getRef(`/cards/${id}/balance/${templateId}`)
            .runTransaction((data: PointBalance) => fn(data))
        },
        updateScratchyBalance: async (
          templateId: keyof (typeof cardData)['balance'],
          fn: (value: ScratchyBalance) => ScratchyBalance,
        ) => {
          await firebaseDb
            .getRef(`/cards/${id}/balance/${templateId}`)
            .runTransaction((data: ScratchyBalance) => fn(data))
        },
      }
    }
  }, [cardData, firebaseDb, id])

  // Update card with lastest logo from merchant
  const merchantData = useMerchantData(
    result.type === 'SUCCESS' ? result.cardData?.merchant : undefined,
  )
  return useMemo(() => {
    if (result.type === 'SUCCESS') {
      return {
        ...result,
        cardData: {
          ...result.cardData,
          logo:
            merchantData?.merchant.cardConfigs?.[result.cardData.cardConfigId]?.logo ??
            result.cardData.logo,
        },
      } satisfies Extract<LoyaltyCardState, { type: 'SUCCESS' }>
    }

    return result
  }, [merchantData?.merchant.cardConfigs, result])
}
