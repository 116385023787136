import { User } from 'firebase/auth'
import useMerchantCardConfigs from 'hooks/useMerchantCardConfigs'
import {
  LoyaltyCard,
  PointLoyaltyCardProps,
  ScratchyLoyaltyCardProps,
  TokenLoyaltyCardProps,
} from 'pages/card/LoyaltyCard'
import { useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useLoyaltyCardStore } from './UseLoyaltyCardStore'
import { useMerchantPrivateStore, useMerchantStore } from './UseMerchantStore'
import { FirebaseDb } from './components/common/Firebase'
import checkIcon from './icons/crown_icon.svg'
import { UnreachableError } from './util/error'

export function LoyaltyCardSheet(props: {
  id: string
  merchantId: string | undefined
  firebaseDb: FirebaseDb
  user: User | undefined | null
}) {
  const state = useLoyaltyCardStore(props.id, props.firebaseDb)
  const [merchant, setMerchant] = useState(props.merchantId)
  const { type: permissionResult } = useMerchantPrivateStore(merchant, props.user, props.firebaseDb)

  useEffect(() => {
    if (state.type === 'SUCCESS' && !merchant) {
      setMerchant(state.cardData.merchant)
    }
  }, [merchant, state])

  const cardTypeProps:
    | PointLoyaltyCardProps
    | TokenLoyaltyCardProps
    | ScratchyLoyaltyCardProps
    | undefined = useMemo(() => {
    if (state.type !== 'SUCCESS') return undefined
    if (state.cardData.merchant !== merchant) setMerchant(state.cardData.merchant)
    const cardData = state.cardData
    const loyaltyType = cardData.bankType
    switch (loyaltyType) {
      case 'tokens':
        return {
          type: loyaltyType,
          bank: cardData,
          updateBalance: (templateId, update) =>
            state.updateTokenBalance!(templateId, (balance) => ({
              ...balance,
              marked: update(balance.marked ?? 0),
            })),

          onClaimClicked: (offerLabel, templateId, cost) =>
            state.updateTokenBalance!(templateId, (balance) =>
              cost > (balance.marked ?? 0) ?
                balance
              : {
                  ...balance,
                  marked: (balance.marked ?? 0) - cost,
                  claimed: (balance.claimed ?? 0) + cost,
                },
            ),
        } satisfies TokenLoyaltyCardProps
      case 'points':
        return {
          type: loyaltyType,
          card: cardData,

          onClaimClickConfirmed: (offerLabel, templateId, cost) =>
            state.updatePointBalance!(templateId, (balance) => ({
              ...balance,
              points: (balance.points ?? 0) - cost,
              claimed: (balance.claimed ?? 0) + cost,
            })),
          onManualEntrySubmitted: (
            templateId: string,
            operation: 'give' | 'take',
            amount: number,
          ) => {
            if (operation === 'take') {
              state.updatePointBalance!(templateId, (balance) => ({
                ...balance,
                points: (balance.points ?? 0) - amount,
                claimed: (balance.claimed ?? 0) + amount,
              }))
            } else if (operation === 'give') {
              state.updatePointBalance!(templateId, (balance) => ({
                ...balance,
                points: (balance.points ?? 0) + amount,
              }))
            }
          },
        } satisfies PointLoyaltyCardProps
      case 'scratchy':
        return {
          type: loyaltyType,
          bank: cardData,
        } satisfies ScratchyLoyaltyCardProps
      default:
        throw UnreachableError(loyaltyType)
    }
  }, [state, merchant])

  return (
    <>
      {state.type === 'SUCCESS' && cardTypeProps && (
        <LoyaltyCard
          {...cardTypeProps}
          templateId={state.cardData.templateId}
          cardConfigId={state.cardData.cardConfigId}
          cardTitle={state.cardData.title ?? undefined}
          fontFamily={'LeagueSpartan'}
          theme={{ backgroundColor: 'black' }}
          logo={state.cardData.logo || undefined}
          markedIcon={checkIcon}
          qrUrl={`${window.location.origin}/card/${props.id}?merchant=${state.cardData.merchant}`}
          editable={permissionResult === 'SUCCESS' && state.cardData.owner !== props.user?.uid}
        />
      )}
      {(state.type === 'NO_CARD' ||
        (state.type === 'FAIL' && state.error.code === 'PERMISSION_DENIED')) &&
        props.user &&
        merchant && <Navigate to={`/merchant/${merchant}`} />}
      {state.type === 'FAIL' && state.error.code !== 'PERMISSION_DENIED' && (
        <>Something went wrong. Try again later...</>
      )}
      {state.type === 'PENDING' && <>Loading card details...</>}
    </>
  )
}
