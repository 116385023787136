import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { ReactComponent as LocationSVG } from 'icons/location.svg'
import { IAddress } from "types/program.module"
import { TwButton } from "./button/ButtonIcon"
import { LOCATION } from 'constants/common'
import CustomInput from './input/CustomInput'
import { Control, FieldError } from 'react-hook-form'

interface ILocationAdditional {
  locations: IAddress[],
  onClick: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void
  addLocations: (e: React.MouseEvent<HTMLButtonElement>) => void
  onChangeInput?: (key: string, value: string) => void
  errors?: FieldError
  control: Control<any>
  name: string
}

const LocationAdditional = ({ locations, onClick, addLocations, errors, control, onChangeInput, name }: ILocationAdditional) => {
  return (<>
    <div className='flex w-full items-center gap-x-2'>
      <CustomInput
        label='Store location'
        name='location'
        type='text'
        error={errors}
        control={control}
        rules={{
          required: locations.length === 0 ? 'Store location is required!' : false,
        }}
      />
      <TwButton
        type='button'
        onClick={addLocations}
        className='mb-[1px] h-9 w-9 !border-2 hover:bg-gray-200'>
        <AddIcon
          className='!fill-gray-300'
          color={'GrayText'}
        />
      </TwButton>
    </div>
    <div className='space-y-1'>
      {locations.length > 0 && (
        <ul className='mb-4 space-y-2'>
          {locations.map((item, idx: number) => {
            return (
              <li
                key={`location-${idx}`}
                className='flex items-start justify-between text-sm font-medium text-gray-500'>
                <span className='flex items-start gap-x-1'>
                  <LocationSVG className='mt-[1px] h-4 w-4 fill-slate-700' />
                  {`${item.address} ${idx >= LOCATION.FREE_LOCATION_NUMBER && !item.isPaid ? `(+$${LOCATION.LOCATION_COST})` : ''}`}
                </span>
                <div className='flex items-start gap-x-3'>
                  {locations.length > 0 && (
                    <TwButton
                      onClick={(e) => onClick(e, item.id)}
                      type='button'
                      className='!border-none !p-1 hover:brightness-150'>
                      <DeleteIcon color={'slategrey'} />
                    </TwButton>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  </>
  )
}

export default LocationAdditional