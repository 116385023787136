import { useMerchantStore } from 'UseMerchantStore'
import { useFirebase } from 'components/common/Firebase'
import { User } from 'firebase/auth'
import { firebaseConfig } from 'services/FirebaseConfig'
import { MerchantEntry } from '../../functions/src/data/common'
import { AddCardResult } from 'types/card.module'

type UseMerchantDataReturnType =
  | {
    merchant: MerchantEntry
  }
  | null
  | undefined

export const useMerchantData = (merchantId?: string): UseMerchantDataReturnType => {
  const { firebaseDb } = useFirebase(firebaseConfig)

  const merchant = useMerchantStore(merchantId, firebaseDb)

  if (!merchantId) {
    return null
  }

  if (merchant.type === 'PENDING') {
    return undefined
  }

  if (merchant.type === 'SUCCESS') {
    return {
      merchant: merchant.merchantData,
    }
  } else {
    return null
  }

}

type UseMerchantListReturnType =
  | {
    merchant: MerchantEntry
    addCard(user: User, email: string, cardConfigId: string): Promise<string | null>
  }
  | null
  | undefined

// export const userMerchantList = (merchantId: string, isLoading = true): UseMerchantListReturnType => {
//   const { firebaseDb } = useFirebase(firebaseConfig)

//   // Ensure hooks are called unconditionally
//   const merchant = useMerchantStore(uid ?? '', firebaseDb)

//   if (!uid) {
//     return null
//   }

//   if (merchant.type === 'PENDING') {
//     return undefined
//   }

//   if (merchant.type === 'SUCCESS') {
//     return {
//       merchant: merchant.merchantData,
//       addCard: merchant.addCard,
//     }
//   } else {
//     return null
//   }
// }
