import { useLoyaltyCardStore } from 'UseLoyaltyCardStore'
import { TwMainGridWrapper } from 'components/modules/twComponent/twLayout.module'
import { useMerchantData } from 'hooks/useMerchantData'
import ProgramCardPanel from 'pages/Program/ProgramCard'
import { useContext, useEffect, useMemo } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import DocumentTitle from 'react-document-title'
import { Navigate, useParams } from 'react-router-dom'
import NotificationPanel from 'ui/NotificationPanel'
import { TokenCardConfig } from '../../../functions/src/data/common'
import { useQueryString } from '../../components/QueryString'
import { useFirebase } from '../../components/common/Firebase'
import { pwaContext } from '../../components/common/NotificationRegistrationProvider'
import { useUserNullable } from '../../components/common/UseUser'
import { firebaseConfig } from '../../services/FirebaseConfig'
import { LiveLoyaltyCard } from './LoyaltyCardStamper'

const LAST_URL_KEY = 'last-url'

export function CardPage() {
  const [merchantId] = useQueryString('merchant')
  const { id: cardId } = useParams()
  const firebase = useFirebase(firebaseConfig)
  const firebaseDb = firebase.firebaseDb
  const loyaltyAuth = firebase.auth
  const { user } = useUserNullable({ auth: loyaltyAuth })
  const merchantData = useMerchantData(merchantId)

  const { notificationRegisterer } = useContext(pwaContext)
  const state = useLoyaltyCardStore(cardId || null, firebaseDb)

  const cardConfigId = state.type === 'SUCCESS' ? state.cardData.cardConfigId : undefined
  const cardData = useMemo(() => {
    if (state.type === 'SUCCESS' && state.cardData) {
      const { bankType, templateId, balance, name } = state.cardData
      const tokenBalance = bankType === 'tokens' && templateId ? balance?.[templateId] : null

      return {
        name,
        marked: tokenBalance?.marked || 0,
        lineMax: tokenBalance?.lineMax || 0,
        target: tokenBalance?.target || 0,
        claimed: tokenBalance?.claimed || 0,
      }
    }

    return { name: undefined, marked: 0, lineMax: 0, target: 0 } // Default return value to avoid undefined
  }, [state])

  const cardConfig = useMemo(
    () =>
      merchantData?.merchant?.cardConfigs && cardConfigId ?
        merchantData?.merchant.cardConfigs?.[cardConfigId]
      : undefined,
    [cardConfigId, merchantData?.merchant.cardConfigs],
  )

  useEffect(() => {
    if (user && merchantId === user?.uid) {
      notificationRegisterer?.requestForNotifications()
    }
  }, [user, merchantId, notificationRegisterer])

  return (
    <DocumentTitle title={'Instant Loyalty - Card'}>
      <section className='layout bg-light-mb-main lg:h-[calc(100vh-5.5rem)]'>
        <TwMainGridWrapper className='h-full'>
          {user && merchantId && (
            <NotificationPanel
              user={user}
              firebaseDb={firebaseDb}
              merchantId={merchantId}
              className='col-span-12 hidden md:col-span-6 lg:col-span-3 lg:block'
            />
          )}
          <div className='col-span-12 grid grid-cols-12 gap-6 md:col-span-12 lg:col-span-9'>
            <div className='col-span-12 h-full rounded p-4 pb-6 md:bg-white md:shadow-md xl:col-span-6'>
              <h2 className='mb-4 px-2 text-xl font-semibold'>
                {cardData?.name ? `${cardData.name}'s Card` : 'Reward'}
              </h2>
              {user === null && <Navigate to={`/merchant/${merchantId}`} />}
              {cardId && user !== null && (
                <div className='space-y-8'>
                  <LiveLoyaltyCard
                    merchantId={merchantId}
                    id={cardId}
                    firebaseDb={firebaseDb}
                    user={user}
                  />
                </div>
              )}
            </div>
          </div>
        </TwMainGridWrapper>
      </section>
    </DocumentTitle>
  )
}
